<div class="global-container">
  <!-- NAVBAR -->

  <div class="navbar-container">
    <img class="big-logo" src="../assets/svgs/logo-big.svg" alt="logo-seniorcontact" draggable="false" routerLink="/home">
    <img class="small-logo" src="../assets/svgs/logo-small.svg" alt="logo-seniorcontact" draggable="false" routerLink="/home">
    <div class="navbar-links">
      <div routerLink="/home" class="navbar-link" [class.navbar-link-selected]="currentRoute.includes('home')">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.6" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
        </svg>
        <p class="paragraph">{{ 'navbar_home' | translate }}</p>
      </div>

      <a class="navbar-link" href="mailto:support@seniorcontact.app" target="_blank">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.6" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
        </svg>
        <p class="paragraph">{{ 'navbar_contact' | translate }}</p>
      </a>
    </div>
  </div>

  <!-- MAIN CONTENT -->

  <router-outlet></router-outlet>

  <!-- FOOTER -->

  <div class="separator-container">
    <div class="separator"></div>
  </div>

  <div class="footer-container">
    <div *ngIf="displayLangPopup" @fadeAnimation class="select-container select-container-footer">
      <div *ngFor="let langItem of allLangs; let first=first; let last=last;" class="select-box" [class.select-start]="first" [class.select-end]="last" (click)="changeLang(langItem)" [class.select-box-selected]="lang == langItem" ontouchstart>
        {{ 'lang_' + langItem | translate }}
        <p *ngIf="langItem != 'en' && langItem != 'fr'" class="subtitle language-beta-tag">BETA</p>
      </div>
    </div>

    <div class="footer-actions">
      <div (click)="displayLangPopup = !displayLangPopup" class="footer-action">
        <img src="../assets/icons/lang.svg" alt="icon-lang">
        <p class="paragraph">{{ 'lang_' + lang | translate }}</p>
      </div>
      <div (click)="toogleChangeTheme()" class="footer-action">
        <img *ngIf="theme == 'light'" src="../assets/icons/sun.svg" alt="icon-theme-light">
        <img *ngIf="theme == 'dark'" src="../assets/icons/moon.svg" alt="icon-theme-dark">
        <p class="paragraph">{{ 'theme_' + theme | translate }}</p>
      </div>
    </div>
    <div class="footer-links">
      <a class="paragraph" routerLink="/terms">{{ 'footer_terms' | translate }}</a>
      <a class="paragraph" routerLink="/privacy">{{ 'footer_privacy' | translate }}</a>
    </div>
  </div>

  <div *ngIf="displayMainOverlay" (click)="displayLangPopup = false" [@.disabled]="!willDismissMainOverlay" @fadeAnimation class="absolute-overlay absolute-overlay-white"></div>
  <div *ngIf="displayLangPopup" (click)="displayLangPopup = false" @fadeAnimation class=" absolute-overlay-gray"></div>
</div>
